<template>
  <div>
    <search-field title="条件筛选">
      <el-form :inline="true" size="small" label-width="100px">
        <el-form-item label="提交时间">
          <div class="block">
            <el-date-picker
              clearable
              v-model="search.searchFields.create_time_start"
              placeholder="请选择"
              type="datetime"
              value-format="timestamp"
              style="margin-right:10px"
            ></el-date-picker>
            至
            <el-date-picker
              clearable
              placeholder="请选择"
              v-model="search.searchFields.create_time_end"
              type="datetime"
              value-format="timestamp"
              style="margin-left:10px"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select clearable v-model="search.searchFields.check_status" placeholder="请选择">
            <el-option
              v-for="item in order_status_list"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询条件" required >
          <el-row class="block">
           <el-col :span="8">
            <el-select v-model="search.searchFields.type" placeholder="请选择">
              <el-option
                v-for="(item) in typeList"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
            </el-col>
            <el-col :span="16">
              <el-input clearable v-model="search.searchFields.receipt_number" placeholder="请输入小票编号"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="最后更新时间">
          <div class="block">
            <el-date-picker
              clearable
              v-model="search.searchFields.update_time_start"
              placeholder="请选择"
              type="datetime"
              value-format="timestamp"
              style="margin-right:10px"
            ></el-date-picker>
            至
            <el-date-picker
              clearable
              placeholder="请选择"
              v-model="search.searchFields.update_time_end"
              type="datetime"
              value-format="timestamp"
              style="margin-left:10px"
            ></el-date-picker>
          </div>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getOrderList(1)">查询</el-button>
        <el-button size="small" @click="getOrderList(1, true)" plain>导出</el-button>

      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="danger" size="small" @click="handleBatch('refuse')">批量拒绝</el-button>
        <el-button type="primary" size="small" @click="handleBatch('pass')">批量通过</el-button>
        <el-button type="warning" size="small" @click="handleBatch('cancel')">批量作废</el-button>
        <el-button type="success" size="small" @click="handleAdd()" plain><i class="el-icon-plus"></i>手工新增销售单</el-button>
      </div>
      <v-table
        :selection="true"
        :data="options.data"
        :columns="options.columns"
        :isBackPage="true"
        :totalCount="options.totalCount"
        :defaultcurrentPage="search.searchFields.page"
        @handleCurrentChange="handlePageChange"
        @handleSizeChange="handleSizeChange"
        @buttonClick="handleButtonClick"
      >
      </v-table>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="remarkDialog"
      @close="remarkDialog = false"
      width="30%">
      <div class="userMessageParent">
        <el-form size="small" class="agentEdit" label-width="100px">
          <el-form-item label="原因">
            <el-input clearable v-model="employee_remark" placeholder="请输入原因" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeStatus">确 定</el-button>
        <el-button @click="remarkDialog = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>

  import vTable from '@/components/tools/vtable'
  import searchField from '@/components/tools/searchField'
  import { BAApi } from '@/api'
  import { formatDate } from '@/libs/utils'

  export default {
    data() {
      return {
        options: {
          columns: [
            {
              label: '小票编号',
              key: 'receipt_number'
            },
            {
              label: '所属BA',
              key: 'name'
            },
            {
              label: '积分',
              key: 'award_point'
            },
            {
              label: 'OCR判别',
              key: 'ocr_status'
            },
            {
              label: '购买日期',
              type: 'render',
              render: (h, { row }) => {
                return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.buy_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
                )
              }
            },
            {
              label: '店铺编号',
               width: 80,
              key: 'shop_number'
            },
            {
              label: '店铺名称',
              key: 'shop_name'
            },
            {
              label: '提交日期',
              type: 'render',
              render: (h, { row }) => {
                return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.create_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
                )
              }
            },
            {
              label: '参考金额',
              width: 80,
              key: 'reference_amount'
            },
            {
              label: '实发金额',
              width: 80,
              key: 'actual_amount'
            },
            {
              label: '订单状态',
              type: 'render',
              render: (h, { row }) => {
                return (
                  <div>
                    <div class="status-td">
                      {
                        <div><span class={`tag tag${Number(row.check_status)}`}></span>{this.checkStatus(row.check_status)}</div>
                      }
                    </div>
                  </div>
                )
              }
            },
            {
              label: '系统审核',
              key: 'system_check_note'
            },
            {
              label: '操作',
              type: 'action',
              selectButton: true,
              buttonInfos: [
                {
                  name: 'refuse',
                  label: '驳回',
                  color: 'danger'
                },
                {
                  name: 'pass',
                  label: '通过',
                  color: 'success'
                },
                {
                  name: 'cancel',
                  label: '作废',
                  color: 'warning'
                },
                {
                  name: 'edit',
                  label: '编辑',
                  color: 'primary'
                },
                {
                  name: 'detail',
                  label: '查看详情',
                  color: 'primary'
                },
              ]
            }
          ],
          data: [],
          totalCount: 0,
          loading: false,
        },
        search: {
          searchFields: {
            page: 1,
            count: 20,
            create_time_start: '',
            create_time_end: '',
            order_status: '',
            issue_status: '',
            unusual_status: '',
            receipt_number: '',
            update_time_start: '',
            update_time_end: '',
            receipt_from: 2,
            check_status: '',
            type: 0
          }
        },
        typeList: [
          {
            code: 0,
            value: '小票编号'
          },
        ],
        order_status_list: [
          {
            code: 0,
            value: '全部'
          },
          {
            code: 1,
            value: '审核中'
          },
          {
            code: 2,
            value: '审核通过'
          },
          {
            code: 3,
            value: '审核拒绝'
          }
        ],
        issue_status_list: [
          {
            code: 0,
            value: '全部'
          },
          {
            code: 1,
            value: '审核中'
          },
          {
            code: 2,
            value: '审核通过'
          },
          {
            code: 3,
            value: '审核拒绝'
          }
        ],
        unusual_status_list: [
          {
            code: 0,
            value: '全部'
          },
          {
            code: 1,
            value: '审核中'
          },
          {
            code: 2,
            value: '审核通过'
          },
          {
            code: 3,
            value: '审核拒绝'
          }
        ],
        check_status_list: [
          {
            code: 0,
            value: '全部'
          },
          {
            code: 1,
            value: '审核中'
          },
          {
            code: 2,
            value: '审核通过'
          },
          {
            code: 3,
            value: '审核拒绝'
          }
        ],
        updateParams: {
          receipt_ids: [],
          check_status: ''
        },
        checkStatusObj: {
          refuse: 4,
          pass: 2,
          cancel: 3
        },
        remarkDialog: false,
        employee_remark: ''
      }
    },

    methods: {
      checkStatus: value => {
        switch (Number(value)) {
          case 1:
            return '待审核'
          case 2:
            return '审核通过'
          case 3:
            return '审核作废'
          case 4:
            return '审核拒绝'
          default:
            break
        }
      },
      // 后端分页
      handlePageChange(page) {
        this.search.searchFields.page = page
        this.getOrderList()
      },
      handleSizeChange(count) {
        this.search.searchFields.count = count
        this.getOrderList(1)
      },
      async getOrderList(page, isExport) {
        this.search.searchFields.page = page || this.search.searchFields.page
        let params = {...this.search.searchFields}
        if(this.search.searchFields.create_time_start) {
        params.create_time_start = formatDate(Number(this.search.searchFields.create_time_start), 'YYYY-MM-DD HH:mm:ss').dateString
      }
      if(this.search.searchFields.create_time_end) {
        params.create_time_end = formatDate(Number(this.search.searchFields.create_time_end), 'YYYY-MM-DD HH:mm:ss').dateString
      }
      if(this.search.searchFields.update_time_start) {
        params.update_time_start = formatDate(Number(this.search.searchFields.update_time_start), 'YYYY-MM-DD HH:mm:ss').dateString
      }
      if(this.search.searchFields.update_time_end) {
        params.update_time_end = formatDate(Number(this.search.searchFields.update_time_end), 'YYYY-MM-DD HH:mm:ss').dateString
      }
        if(!isExport) {
          try {
            let data = (await BAApi.getOrderList(params)).data
            if (data.code === 0) {
              this.$validator.reset()
              this.options.data = data.receipt_list.map(item => {
                if(Number(item.check_status) > 1) {
                  item.btnList = ['detail']
                } else {
                  item.btnList = ['refuse', 'pass', 'cancel', 'edit', 'detail']
                }
                return item
              })
              this.options.totalCount = parseInt(data.total_count) || 0
            } else {
              this.$error(data)
            }
          } catch (err) {
            console.error(err)
          }
        } else {
          params.page = ''
          params.count = ''
          this.importList(params)
        }
      },
      clearSearch() {
        this.search = {
          searchFields: Object.assign({ count: this.search.searchFields.count }, {
            page: 1,
            create_time_start: '',
            create_time_end: '',
            order_status: '',
            issue_status: '',
            unusual_status: '',
            receipt_number: '',
            update_time_start: '',
            update_time_end: '',
            receipt_from: 1,
            type: 0
          })
        }
      },
      handleButtonClick(value) {
        let type = value.button,
          data = value.data
        if(['refuse', 'cancel'].includes(type)) {
          this.updateParams.receipt_ids.push(data.id)
          this.updateParams.check_status = this.checkStatusObj[type]
          this.remarkDialog = true
          this.employee_remark = ''
        } else if(type === 'pass') {
          this.updateParams.receipt_ids.push(data.id)
          this.updateParams.check_status = this.checkStatusObj[type]
          this.changeStatus()
        } else {
          this.$router.push({
            name: 'orderOnlineDetail',
            query: {
              id: data.id,
              canEdit: type === 'edit' ? 1 : 0
            }
          })
        }
      },
      handleAdd() {
        this.$router.push({
          name: 'orderOnlineAdd'
        })
      },
      handleBatch(type) {
        let data = this.$store.state.Table.rowAction
        if (data.length > 0) {
          let ids = data.map(item => item.id)
          this.updateParams.receipt_ids = ids
          this.updateParams.check_status = this.checkStatusObj[type]
          if(['cancel', 'refuse'].includes(type)) {
            this.remarkDialog = true,
            this.employee_remark = ''
          } else {
            this.changeStatus()
          }
        } else {
          this.$message.error('请先进行选择')
          return
        }
      },
      async changeStatus() {
        let loading = this.$loading()
        let params = {
          receipt_ids: this.updateParams.receipt_ids.join(','),
          check_status: this.updateParams.check_status,
          employee_remark: this.employee_remark
        }
        try {
          let data = (await BAApi.updateOrderStatus(params)).data
          if (data.code === 0) {
            this.$message.success('操作成功')
            this.updateParams.receipt_ids = []
            this.getOrderList()
          } else {
            this.$error(data)
          }
        } catch (err) {
          console.error(err)
        } finally {
          loading.close()
        }
      },
      async importList(params) {
        try {
          let data = (await BAApi.exportOrderList(params)).data
          if (data.code) {
            this.$error(data)
            return
          }
          let time = formatDate(new Date(), 'yyyy-MM-dd')
          let downLink = document.createElement('a')
          downLink.href = data.file_path
          downLink.download = `${time}-订单(云店)列表`
          downLink.click()
        } catch (error) {
          console.log(error)
        }
      }
    },

    mounted() {
      this.getOrderList()
    },
    components: {
      vTable, searchField
    }
  }
  </script>

  <style lang="less">
  .status-td {
    display: flex;
    align-items: center;
    justify-content: center;
    .tag {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 6px;
    }
    .tag1 {
      background-color: #409EFF;
    }
    .tag2 {
      background-color: #67C23A;
    }
    .tag3 {
      background-color: #E6A23C;
    }
    .tag4 {
      background-color: #F56C6C;
    }
  }
  </style>
